<template>
    <van-loading size="24px" vertical style="margin-top: 50%">加载中...</van-loading>
</template>

<script>
  import {Loading} from 'vant';
  import * as api from '@/api/mobile'
  export default {
    name: "shareJump",
    components: {
      [Loading.name]: Loading,
    },
    created() {
      api.get_pk_info_by_sms_id({sms_id: this.$route.query.id}).then(res => {
        this.$router.push({
          path: res.data.data.sms_show_page,
          query: res.data.data
        })
      })
    }
  }
</script>

<style scoped>

</style>
